<template>
  <div class="page--mask-station">
    <div class="container-mask-station">
      <img src="/static/img/mask-station/logo.png" class="logo" />

      <div class="banner">
        <h2 class="banner__copywriting">What's <br />the best<br />face mask<br />for you?</h2>
      </div>

      <div class="primary-nav">
        <div class="row">
          <div class="col-sm-6 col">
            <p class="primary-nav__copywriting">Scan QR code from SOCO App</p>
            <div class="primary-nav__menu">
              <qrcode-vue
                :value="QRcode || 'default'"
                :size="'147'"
                level="H"
                class="primary-nav__icon-qrcode"
              ></qrcode-vue>
              <span class="primary-nav__text"> SCAN HERE </span>
            </div>
          </div>

          <div class="col-sm-6 col">
            <p class="primary-nav__copywriting">Get product recommendation!</p>
            <router-link to="/mask-station/play-quiz" class="primary-nav__menu">
              <img src="/static/img/mask-station/play.svg" class="primary-nav__icon-play" />
              <span class="primary-nav__text primary-nav__text--play-quiz">
                PLAY QUIZ <img src="/static/img/mask-station/arrow-play.svg" />
              </span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="box">
        <div class="box__paraf">Aplikasi mobile <span class="bolder">SOCO</span> tersedia di iOS dan Android</div>
        <div class="group-btn text-center">
          <a href="" class="btn btn-dark btn-download btn-download--appstore"></a>
          <a href="" class="btn btn-dark btn-download btn-download--playstore"></a>
        </div>
      </div>
    </div>
    <img src="/static/img/mask-station/masker-with-hand.png" class="main-banner" />
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import SocketIO from 'socket.io-client';

const Config = require('~/config/default.env').default;
const QR_CODE_LIFE_TIME = 10 * 60 * 1000;
// const GET_TOKEN_INTERVAL = 1000;
const socket = SocketIO(`${Config.MS_SOCKET_API_URL}/store`, { transports: ['websocket'] });

export default {
  name: 'maskstation',
  components: {
    QrcodeVue,
  },
  computed: {
    userProfile() {
      return this.$store.state.auth.userProfile;
    },
    QRcode() {
      return this.$store.state.QRcode.value || '';
    },
  },
  watch: {
    userProfile() {
      if (this.$SO.size(this.userProfile)) {
        this.$router.push('/mask-station/login-success');
      }
    },
  },
  created() {
    Cookies.set('token', '', { expires: 0 });
    this.initQRCode();

    const self = this;
    socket.on('qr_code_login', function (data) {
      if (self.$store.state.QRcode.value == data.qr_code) {
        Cookies.set('token', data.token, { expires: 1 });
        self.$store.commit('authToken', data.token);
        self.$store.dispatch('getToken', { redirect: '/mask-station/login-success', data: self });
        socket.off('qr_code_login');
      }
    });
  },
  methods: {
    initQRCode() {
      this.$store.dispatch('generateQrCode');

      // re-generate qr code every 10 minutes
      this.qrGenTimer = setInterval(() => {
        if (this.$store.state.QRcode.isLoggedIn) {
          // stop re-generation after logging in
          return clearInterval(this.qrGenTimer);
        }
        this.$store.dispatch('generateQrCode');
      }, QR_CODE_LIFE_TIME);
    },
  },
};
</script>
